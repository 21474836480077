.registration {
  background-color: #fff;
  flex-direction: column;
  display: flex;
}

.reg-title {
  text-align: center;
  justify-content: center;
  padding-top: 2em;
  font-size: 2.5em;
  display: flex;
}

.description {
  gap: 40px;
  width: 100%;
}

@media (width <= 450px) {
  .reg-title {
    text-align: center;
    font-size: 1.5em;
  }
}

.blog-card {
  z-index: 0;
  background: #fff;
  border-radius: 5px;
  flex-direction: column;
  margin: 1rem auto 5.6%;
  font-family: sans-serif;
  line-height: 1.4;
  display: flex;
  overflow: hidden;
  box-shadow: 0 3px 7px -1px #0000001a;
}

.blog-card a {
  color: inherit;
}

.blog-card a:hover {
  color: #5ad67d;
}

.blog-card:hover .photo {
  transition: all .5s;
}

.blog-card .photo-cont {
  width: 200px;
  height: 200px;
  position: relative;
}

.blog-card .photo {
  width: 100%;
  height: 100%;
}

.blog-card .description {
  z-index: 1;
  background: #fff;
  flex-direction: column;
  justify-content: space-around;
  padding: 1.5rem;
  display: flex;
  position: relative;
}

.blog-card .description h1, .blog-card .description h2 {
  font-family: Poppins, sans-serif;
}

.blog-card p {
  margin: 1rem 0 0;
  position: relative;
}

.blog-card p:first-of-type:before {
  content: "";
  background: #00f;
  border-radius: 3px;
  width: 35px;
  height: 5px;
  position: absolute;
  top: -.75rem;
}

@media (width >= 640px) {
  .reg-title {
    padding-top: 1em;
  }

  .blog-card {
    flex-direction: row;
    max-width: 1200px;
    min-height: 400px;
  }

  .blog-card .photo {
    background-size: cover;
  }

  .blog-card .meta {
    flex-basis: 40%;
    height: auto;
  }

  .blog-card .description {
    flex-basis: 60%;
  }

  .blog-card .description:before {
    content: "";
    z-index: -1;
    background: #fff;
    width: 30px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: -10px;
    transform: skewX(-3deg);
  }

  .blog-card.alt {
    flex-direction: row-reverse;
  }

  .blog-card.alt .description:before {
    left: inherit;
    right: -10px;
    transform: skew(3deg);
  }

  .blog-card.alt .details {
    padding-left: 25px;
  }
}

.button {
  color: #fff;
  text-align: center;
  cursor: pointer;
  border-radius: 50px;
  margin: 4px 2px;
  padding: 16px 32px;
  font-size: 16px;
  text-decoration: none;
  transition-duration: .4s;
  display: inline-block;
}

.button1 {
  color: #000;
  background-color: #fff;
  border: 2px solid #00f;
}

.button1:hover {
  color: #fff;
  background-color: #4caf50;
}

.button2 {
  color: #000;
  background-color: #fff;
  border: 2px solid #008cba;
  width: 100%;
}

.button2:hover {
  color: #fff;
  background-color: #008cba;
}
/*# sourceMappingURL=index.9d61bf5a.css.map */
