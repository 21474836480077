.registration{
    background-color:rgb(255, 255, 255);
    display: flex;
    flex-direction: column; 
    /* height: 50rem; */
}

.reg-title{
    padding-top: 2em;
    display: flex;
    justify-content: center;
    font-size:2.5em;
    text-align: center;
}

.description{
    width: 100%;
    gap: 40px;
}

@media (max-width:450px) {
    .reg-title{
        font-size:1.5em;
        text-align: center;
    }
    
}


.blog-card {
    display: flex;
    flex-direction: column;
    margin: 1rem auto;
    box-shadow: 0 3px 7px -1px rgba(0, 0, 0, 0.1);
    margin-bottom: 5.6%;
    background: #fff;
    line-height: 1.4;
    font-family: sans-serif;
    border-radius: 5px;
    overflow: hidden;
    z-index: 0;
}

.blog-card a {
    color: inherit;
}

.blog-card a:hover {
    color: #5ad67d;
}

.blog-card:hover .photo {
    transition: 500ms;
}

.blog-card .photo-cont {
    position: relative;
    height: 200px;
    width: 200px;
}

.blog-card .photo {
    /* position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;*/
    width: 100%;
    height: 100%;
}


.blog-card .description {
    padding: 1.5rem;
    background: rgb(255, 255, 255);
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    z-index: 1;
}

.blog-card .description h1,
.blog-card .description h2 {
    font-family: Poppins, sans-serif;
}




.blog-card p {
    position: relative;
    margin: 1rem 0 0;
}



.blog-card p:first-of-type:before {
    content: "";
    position: absolute;
    height: 5px;
    background: blue;
    width: 35px;
    top: -0.75rem;
    border-radius: 3px;
}


@media (min-width: 640px) {
    .reg-title{
        padding-top: 1em;
    }
    .blog-card {
        flex-direction: row;
        max-width: 1200px;
        min-height: 400px;
    }
    .blog-card .photo {
        background-size: cover;
    }
    .blog-card .meta {
        flex-basis: 40%;
        height: auto;
    }
    .blog-card .description {
        flex-basis: 60%;
    }
    .blog-card .description:before {
        transform: skewX(-3deg);
        content: "";
        background: #fff;
        width: 30px;
        position: absolute;
        left: -10px;
        top: 0;
        bottom: 0;
        z-index: -1;
    }
    .blog-card.alt {
        flex-direction: row-reverse;
    }
    .blog-card.alt .description:before {
        left: inherit;
        right: -10px;
        transform: skew(3deg);
    }
    .blog-card.alt .details {
        padding-left: 25px;
    }
}


.button {
    color: white;
    padding: 16px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    border-radius: 50px;
    font-size: 16px;
    margin: 4px 2px;
    transition-duration: 0.4s;
    cursor: pointer;
}

.button1 {
    background-color: white;
    color: black;
    border: 2px solid blue;
}

.button1:hover {
    background-color: #4CAF50;
    color: white;
}

.button2 {
    width: 100%;
    background-color: white;
    color: black;
    border: 2px solid #008CBA;
}

.button2:hover {
    background-color: #008CBA;
    color: white;
}